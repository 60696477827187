document.addEventListener("turbo:load", () => {

  const accordionElems = document.querySelectorAll('.content-accordion-item');

  accordionElems.forEach((accordionElem) => {
    accordionElem.addEventListener('click', function (event) {
      accordionElem.classList.toggle('active');
    }, false);
  })

})
