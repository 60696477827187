document.addEventListener("turbo:load", () => {
  const mobile_navigation = document.getElementById("mobile-navigation");
  const body = document.getElementById("body");

  document.querySelector("a[href='#open-mobile-navigation']").addEventListener("click", function (evnt) {
    evnt.preventDefault();
    mobile_navigation.classList.add("open");
    body.classList.add("mobile-navigation-open");
  })

  document.querySelector("a[href='#close-mobile-navigation']").addEventListener("click", function (evnt) {
    evnt.preventDefault();
    mobile_navigation.classList.remove("open");
    body.classList.remove("mobile-navigation-open");
  
    document.querySelectorAll(".mobile-navigation-sub").forEach(function(item) {
      item.classList.remove('open');
    })

  })

  document.querySelectorAll("a[href='#open-mobile-navigation-sub']").forEach(function(item) {
    item.addEventListener("click", function (evnt) {
      evnt.preventDefault();

      document.querySelectorAll(".mobile-navigation-sub").forEach(function(item) {
        item.classList.remove('open');
      })

      item.nextElementSibling.classList.add("open");
      body.classList.add("mobile-navigation-open");
    })

  })

  document.querySelectorAll("a[href='#close-mobile-navigation-sub']").forEach(function(item) {
    item.addEventListener("click", function (evnt) {
      evnt.preventDefault();
      body.classList.remove("mobile-navigation-open");
      document.querySelectorAll(".mobile-navigation-sub").forEach(function(item) {
        item.classList.remove('open');
      })
    })

  })

})

