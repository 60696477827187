import { Controller } from "@hotwired/stimulus"
let filterMonths = [] 

export default class extends Controller {
  static targets = [ 'event', 'tabs', 'tab' ]

  connect() {
    filterMonths = [] 
    this.collectMonths()
    this.createTabs()
  }

  collectMonths() {
    this.eventTargets.forEach(eventTarget => {
      if ( !filterMonths.includes(eventTarget.dataset.filterDate) ) {
        filterMonths.push(eventTarget.dataset.filterDate)
      }
    })
  }

  createTabs() {
    filterMonths.forEach(filterMonth => {
      let li = document.createElement("li")
      li.appendChild(this.createTabLink(filterMonth))
      li.classList.add("nav-item")
      this.tabsTarget.appendChild(li)
    })
  }

  createTabLink(filterMonth){
    let a = document.createElement("a")
    a.className = "nav-link p-3 static"
    a.innerText = filterMonth
    a.dataset.filterDate = filterMonth
    a.dataset.action = "click->events#filterEvents:prevent"
    a.dataset.eventsTarget = "tab"
    a.setAttribute("role", "button")
    a.setAttribute("href", "javascript:;")
    return a
  }

  filterEvents(event) {
    this.tabTargets.forEach(tabItem => tabItem.classList.remove('active'))
    this.eventTargets.forEach(eventItem => {
      eventItem.hidden = true
      if (eventItem.dataset.filterDate == event.target.dataset.filterDate) {
        eventItem.hidden = false
      }
    })
    event.target.classList.add('active')
  }

  resetEvents(event) {
    this.tabTargets.forEach(tabItem => tabItem.classList.remove('active'))
    this.eventTargets.forEach(eventItem => eventItem.hidden = false)
    event.target.classList.add('active')
  }

}

