import Plyr from 'plyr'

document.addEventListener("turbo:load", () => {

  const players = Array.from(document.querySelectorAll('.js-player')).map((p) => new Plyr(p, {
      i18n: {
        restart: 'Neustart',
        rewind: 'Zurückspulen {seektime}s',
        play: 'Abspielen',
        pause: 'Pause',
        fastForward: 'Vorspulen {seektime}s',
        seek: 'Seek',
        seekLabel: '{currentTime} von {duration}',
        played: 'Abgespielt',
        buffered: 'Zwischengespeichert',
        currentTime: 'Aktuelle Zeit',
        duration: 'Dauer',
        volume: 'Lautstärke',
        mute: 'Ton aus',
        unmute: 'Ton an',
        enableCaptions: 'Untertitel aktivieren',
        disableCaptions: 'Untertitel deaktivieren',
        download: 'Download',
        enterFullscreen: 'Vollbildmodus aktivieren',
        exitFullscreen: 'Vollbildmodus beenden',
        frameTitle: 'Player für {title}',
        captions: 'Untertitel',
        settings: 'Einstellungen',
        pip: 'PIP',
        menuBack: 'Zurück zum letzten Menü',
        speed: 'Geschwindigkeit',
        normal: 'Normal',
        quality: 'Qualität',
        loop: 'Loop',
        start: 'Start',
        end: 'Ende',
        all: 'Alles',
        reset: 'Reset',
        disabled: 'Deaktiviert',
        enabled: 'Aktiviert',
        advertisement: 'Werbung',
        qualityBadge: {
          2160: '4K',
          1440: 'HD', 
          1080: 'HD',
          720: 'HD',
          576: 'SD',
          480: 'SD',
        },
      },
    }
  ))

})

