import lozad from 'lozad'
const observer = lozad()

document.addEventListener("turbo:load", () => {
  observer.observe()
})

document.addEventListener("turbo:frame-load", () => {
  observer.observe()
})
