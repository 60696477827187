import Swiper from 'swiper'; 

document.addEventListener("turbo:load", () => {

  const swiper_topics = new Swiper('.search-topics', {
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      992: {
        slidesPerView: 2,
      },
    },
  });

  const swiper_articles = new Swiper('.search-articles', {
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });

  const swiper_releases = new Swiper('.search-releases', {
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });

})
