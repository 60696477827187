import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import { de } from "@fancyapps/ui/dist/fancybox/l10n/de.esm.js";

const icon = document.createElement("i");
icon.className = "far fa-arrow-up-right fa-fw";

document.addEventListener("turbo:load", () => {

  Fancybox.bind("[data-fancybox]", {
    l10n: de
  });

  document.querySelectorAll('section.content a').forEach(link => {
    if (link.hostname !== location.hostname) {
      link.setAttribute('target', '_blank');
      if (!link.classList.contains("static") && !link.classList.contains("external") && !link.classList.contains("image") && !link.classList.contains("mailto")) {
        link.classList.add("external");
        link.append(" ");
        link.append(icon.cloneNode());
      }
    }
  })

  document.querySelectorAll('section.article-elements a').forEach(link => {
    if (link.href.substr(link.href.lastIndexOf('.') + 1) == 'pdf') {
      link.setAttribute('target', '_blank');
    }
  })

})
